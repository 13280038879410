import React from 'react';

import ContentLayout from 'components/ContentLayout'

import Thanks from 'containers/Thanks'

export default () => (
  <ContentLayout title="補正文件上傳">
    <Thanks />
  </ContentLayout>
)
